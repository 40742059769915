import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import RenderArticle from '../../components/Render/RenderArticle';
import toast, { Toaster } from 'react-hot-toast';
import Editor from '../../pages/blog/Editor';

import postImage from '../../assets/img/blog/inner/1.jpg';
import innerImage from '../../assets/img/blog/inner/2.jpg';

import cdn_api from '../../api/cdn_api';
import apis from '../../api/apis';
import api from '../../api/api';

const PostContent = (props) => {
    const { blog } = props;
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [edit, setEdit] = React.useState(false);

    const onDelete = () => {
        if (!blog || !blog.id || !user) return;
        api.delete('/blog?id=' + blog.id, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`,
            },
            withCredentials: true,
        })
            .then(res => {
                toast.success('Blog deleted successfully');
                new Promise(r => setTimeout(r, 2000));
                window.location.href = '/blog';
            })
            .catch(err => {
                toast.error('Failed to delete blog');
                console.log(err);
            });
    }

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleDelete = () => {
        setShowDropdown(false);
        if (window.confirm('Are you sure you want to delete this blog?')) {
            onDelete();
        }
    };

    const handleEdit = () => {
        setShowDropdown(false);
        setEdit(true);
        // window.location.href = `/write?id=${blog.id}`;
    }

    return (
        <>
            {
                (!edit) ?
                <div className="blog-deatails">
                    <Toaster />
                    {/* <div className="bs-img">
                        {
                            blog && blog.cover && blog.cover !== '' &&
                            <img
                                width="100%"
                                src={apis.cdn_base_url + '/file?filename=' + blog.cover}
                                alt="Post Image"
                            />
                        }

                    </div> */}
                    <div className="blog-full">

                        <h2 className="title">{blog.title}</h2>

                        <ul className="single-post-meta">
                            <li>
                                <span className="p-date"> <i className="fa fa-calendar-check-o"></i> {new Date(blog.created_at).toDateString()} </span>
                            </li>
                            <li>
                                <span className="p-date"> <i className="fa fa-user-o"></i> {blog.creator_name} </span>
                            </li>
                            <li className="Post-cate">
                                <div className="tag-line">
                                    <i className="fa fa-book"></i>
                                    {/* <Link to="/blog">Strategy</Link> */}
                                    {
                                        blog.categories && blog.categories.map((category, i) => (
                                            <>
                                                <Link key={i} to={`/blog?category=${category}`}>{category}</Link>
                                                <span>{i !== blog.categories.length - 1 ? ', ' : ''}</span>
                                            </>
                                        ))
                                    }
                                </div>
                            </li>
                            <li className="post-comment"> <i className="fa fa-comments-o"></i> {blog?.comments?.length}</li>
                            {
                                user && user.email === blog.creator_email &&
                                <div className='ml-100' style={{ position: 'relative', cursor: 'pointer' }}>
                                    <span onClick={toggleDropdown}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                        </svg>
                                    </span>
                                    {showDropdown && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '20px',
                                                right: '0',
                                                backgroundColor: 'white',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                zIndex: '1000',
                                                width: '120px',
                                                cursor: 'pointer',
                                                fontFamily: 'monospace',
                                            }}
                                        >
                                            <ul className='border m-1' style={{ listStyle: 'none', margin: 0, padding: '5px 10px' }}>
                                                <li onClick={handleEdit} style={{ cursor: 'pointer' }}>EDIT</li>
                                            </ul>
                                            <ul className='border m-1' style={{ listStyle: 'none', margin: 0, padding: '5px 10px' }}>
                                                <span class="d-inline-block" onClick={handleDelete} style={{ cursor: 'pointer' }}>DELETE</span>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            }
                        </ul>

                        <div className="blog-desc">
                            {props?.blog?.content && <RenderArticle data={JSON.parse(props.blog.content)} />}
                        </div>
                    </div>
                </div>:
                <div>
                    {props?.blog && <Editor blog={props.blog} />}
                </div>
            }
        </>
    )
}

export default PostContent;