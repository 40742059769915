import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import api from '../../api/api';
import toast from 'react-hot-toast';

const TeamRegistrationForm = ({ contest, user }) => {
    const [teamName, setTeamName] = useState('');
    const [phone, setPhone] = useState('');
    const [members, setMembers] = useState([{ name: '', email: '', institute: '', session: '', student_id: '' }]);
    const [errors, setErrors] = useState('');

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedMembers = [...members];
        updatedMembers[index][name] = value;
        setMembers(updatedMembers);
    };

    const handleAddMember = () => {
        if (members.length < contest.max) {
            setMembers([...members, { name: '', email: '', institute: '', session: '', student_id: '' }]);
            setErrors('');
        } else {
            setErrors(`You can add up to ${contest.max} members.`);
        }
    };

    const handleRemoveMember = (index) => {
        const updatedMembers = [...members];
        updatedMembers.splice(index, 1);
        setMembers(updatedMembers);
        setErrors('');
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (members.length < contest.min) {
            setErrors(`You need at least ${contest.min} members.`);
            return;
        }
        const isAnyFieldEmpty = members.some(member =>
            !member.name || !member.email || !member.institute || !member.session || !member.student_id
        );
        if (!teamName || !phone || isAnyFieldEmpty) {
            setErrors('Please fill out all fields.');
            return;
        }

        const info = {
            contest_id: contest.id,
            team_name: teamName,
            phone: phone,
            member_count: members.length,
            members: members
        };
        // Proceed with form submission
        console.log(info);
        try {
            await api.post('/user/contest', info, {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`,
                    "Content-type": "application/json",
                },
                withCredentials: true,
            })
            .then(response => {
                toast.success('Team registered successfully');
            })
            .catch(error => {
                console.log(error);
                toast.error('Failed to register team');
            });
        } catch (error) {
            console.log(error);
            toast.error('Failed to register team');
        }
    };

    return (
        <div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card h-100">
                            <div className="card-body">
                                <h4 className="card-title">Team Registration</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="team_name">Team Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="team_name"
                                            value={teamName}
                                            onChange={(e) => setTeamName(e.target.value)}
                                            placeholder="Enter Team Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phone"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            placeholder="Enter Phone"
                                        />
                                    </div>
                                    <h5>Team Members:</h5>
                                    {members.map((member, index) => (
                                        <div key={index} className="form-group border p-2 mb-4">
                                            <label htmlFor={`member_name_${index}`}>Member {index + 1}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Name"
                                                value={member.name}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            <input
                                                type="email"
                                                className="form-control mt-2"
                                                name="email"
                                                placeholder="Email"
                                                value={member.email}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            <input
                                                type="text"
                                                className="form-control mt-2"
                                                name="institute"
                                                placeholder="Institute"
                                                value={member.institute}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            <input
                                                type="text"
                                                className="form-control mt-2"
                                                name="session"
                                                placeholder="Session"
                                                value={member.session}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            <input
                                                type="text"
                                                className="form-control mt-2"
                                                name="student_id"
                                                placeholder="Student ID"
                                                value={member.student_id}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            <button 
                                                type="button" 
                                                className="btn-shop btn mt-2" 
                                                style={{
                                                    backgroundColor:'#ff8282',
                                                    color: 'white',
                                                    width: '100%',
                                                    height: '20px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }} 
                                                onClick={() => handleRemoveMember(index)}>
                                                Remove Member
                                            </button>
                                        </div>
                                    ))}

                                    {
                                        members.length < contest.max &&
                                        <Button
                                            style={{ backgroundColor: '#f0f0f0', color: '#000', border: '1px solid #000', height: '25px' }}
                                            onClick={handleAddMember}
                                            className="w-100 mt-2 rounded-lg d-flex align-items-center justify-content-center"
                                        >
                                            <i className="fa fa-plus mr-2" />
                                             Add Team Member
                                        </Button>
                                    }
                                    <br />
                                    <div
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <button type="submit" className="btn-shop orange-color ml-3 w-100">
                                            Submit
                                        </button>
                                    </div>
                                    {errors && <div className="alert alert-danger mt-3">{errors}</div>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamRegistrationForm;
