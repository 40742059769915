import cdn_api from "../../api/cdn_api";
import toast, { Toaster } from 'react-hot-toast';

const FileUpload = async (file) => {
    if (!file) {
        toast.error("No File Selected");
        return null;
    }
    try {
        const access_token = await JSON.parse(localStorage.getItem('user'))?.access_token;
        if (!access_token) {
            toast.error("User Not Logged In");
            return null;
        }
        // console.log(file);
        const formData = new FormData();
        formData.append("key1", file);
        const res = await cdn_api.post("/file",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${access_token}`,
                },
                withCredentials: true,
            }
        );

        if (!res.data.upload_success?.key1) {
            toast.error("Image Not Uploaded");
            return null;
        }
        toast.success("Image Uploaded Successfully");
        return res.data.upload_success?.key1;
    } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Image Upload Failed");
        return null;
    }
};

export default FileUpload;