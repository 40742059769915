import React from 'react';
import formatDate from '../../components/utils/FormatDate';

const SiteBreadcrumb = (props) => {
	const { contest } = props;
	return (
		<div className={'rs-breadcrumbs breadcrumbs-overlay bg-white'}>
			<div className="container">
				<div className="row">
					<div className="col-md-6 d-flex align-items-center" align="left">
						<ul>
							<li 
								style={{ 
									fontSize: '2.5rem', 
									fontWeight: '600', 
									padding: '5px',
									lineHeight: '1'
								}}
							>
								{contest?.title}
							</li>
							{/** Kaggle URL */}
							{/* <li>
								<a href={contest?.kaggle_url} target="_blank" rel="noreferrer">
									<i className="fa fa-link"></i> Kaggle Competition
								</a>
							</li> */}
							{/** Registration Deadline */}
							<li>
								<i className="fa fa-calendar"></i> Registration Deadline: <b>{formatDate(contest?.reg_deadline)}</b>
							</li>
							{/** Submission Deadline */}
							<li>
								<i className="fa fa-calendar"></i> Submission Deadline: <b>{formatDate(contest?.submission_deadline)}</b>
							</li>
							{/** Onsite Date */}
							<li>
								<i className="fa fa-calendar"></i> Onsite: <b>{formatDate(contest?.onsite)}</b>
							</li>
							{/** Fee */}
							<li>
								<i className="fa fa-money"></i> Reg Fee: <b>{contest?.fee} BDT</b>
							</li>
							{/** Team Size */}
							<li>
								<i className="fa fa-users"></i> Team Size: <b>Min: {contest?.min}, Max: {contest?.max}</b>
							</li>
						</ul>
					</div>

					<div className="col-md-6 d-flex justify-content-center align-items-center">
						<div className="breadcrumbs-img">
							{
								contest &&
								<img
									className='img-fluid border border-5 border-gray rounded'
									src={contest?.cover_photo}
									alt="Contest Cover"
									style={{
										maxWidth: '70%',
										maxHeight: '70vh',
										display: 'block',
									}}
								/>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;
