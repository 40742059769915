import React from 'react';
import { Link } from 'react-router-dom';
import formatDate from '../../components/utils/FormatDate';
import img from '../../assets/img/bg/12.jpg';

const CourseSingleTwo = (props) => {
    const { courseClass, courseImg, courseTitle, catLink, coursePrice, courseCategory, upvote, userRating, topicName, date } = props;
    return (
        <div className={courseClass ? courseClass : 'courses-item'}>
            {
                props.viewList &&
                <div className="img-part">
                    <img
                        src={courseImg || img}
                        alt={courseTitle}
                    />
                </div>
            }
            <div className="content-part">
                <ul className="meta-part">
                    {/* <li><span className="price">{coursePrice ? coursePrice : '55.00'}</span></li> */}
                    <li><Link className="categorie" to={catLink ? catLink : 'course-categories'}>{topicName ? topicName : 'Web Development'}</Link></li>
                </ul>
                <h3 className="title"><Link to={"/course/course-single?id=" + props.id}>{courseTitle ? courseTitle : 'Become a PHP Master and Make Money Fast'}</Link></h3>
                <div className="blog-desc">
                    {
                        props?.desc &&
                        <div dangerouslySetInnerHTML={{ __html: props?.desc }}></div>
                    }
                </div>
                <div>
                    {
                        date &&
                        <div className="comment-meta" style={{ fontSize: '12px' }}>
                            <a href="#">{formatDate(date)}</a>
                        </div>
                    }
                </div>
                <div className="bottom-part">
                    <div className="info-meta">
                        <ul>
                            <li className="user"><i className="fa fa-arrow-up"></i> {upvote ? upvote : '0'}</li>
                            <li className="ratings">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span>({userRating ? userRating : '05'})</span>
                            </li>
                        </ul>

                    </div>
                    <div className="btn-part">
                        <Link to={"/course/course-single?id=" + props.id}>
                            {props.btnText}<i className="flaticon-right-arrow"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseSingleTwo