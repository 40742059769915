import React from 'react';
import { Helmet } from 'react-helmet';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'universal-cookie';

import auth_api from '../api/auth_api';

import Header from '../components/Layout/Header/Header';
import Footer from '../components/Layout/Footer/Footer';
import ScrollToTop from '../components/Common/ScrollTop';
import OffWrap from '../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../components/Common/Breadcumb';
import SearchModal from '../components/Layout/Header/SearchModal';

// Image
import logo from '../assets/img/logo/csai.png';
import icon from '../assets/img/logo/ai.png';

import bannerbg from '../assets/img/breadcrumbs/inner7.jpg';

const Login = () => {

    const cookies = new Cookies();

    async function getUserInfo(codeResponse) {
        const response = await auth_api.post("/google_login",
            { code: codeResponse.code },
            { headers: { "Content-Type": "application/json" } }
        )
        if (await response.data.user) {
            return await response.data.user;
        }else{
            return null;
        }
    }
    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
            const loginDetails = await getUserInfo(codeResponse);
            console.log(loginDetails);
            if (loginDetails) {
                localStorage.setItem("user", JSON.stringify(loginDetails));
                cookies.set("access_token", loginDetails.access_token, { path: "/", maxAge: 3600*24*7 });
                window.location.href = "/";
            }else{
                alert("Failed to login");
            }
        },
        onError: (error) => {
            alert(
                "Failed to login"
            );
        },
    });

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={icon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='others'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='support@website.com'
                Location='374 William S Canning Blvd, MA 2721, USA '
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Login"
                pageName="Login"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}




            {/* Login Part Start */}

            {/** GOOGLE OAUTH LOGIN */}
            <div className="rs-login pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="noticed">
                        <div className="main-part">
                            <div className="method-account" align="center">
                                <h2 className="login">Login</h2>
                                <button
                                    onClick={googleLogin}
                                    class="google-btn border-1 border-primary"
                                >
                                    <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" />
                                    <span>Sign in with Google</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="rs-login pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="noticed">
                        <div className="main-part">
                            <div className="method-account">
                                <h2 className="login">Login</h2>
                                <form>
                                    <input type="email" name="E-mail" placeholder="E-mail" required />
                                    <input type="text" name="text" placeholder="Password" required />
                                    <button type="submit" className="readon submit-btn">login</button>
                                    <div className="last-password">
                                        <p>Not registered? <Link to="/register">Create an account</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Login Part End */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>

    );
}


export default Login;