import React from 'react';
// import SiteBreadcrumb from '../../components/Common/Breadcumb';
// import bannerbg from '../../assets/img/breadcrumbs/inner12.jpg';
import Editor from './Editor';

const EditorMain = () => {

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            {/* <SiteBreadcrumb
                pageTitle="Blog Editor"
                pageName="Blog Editor"
                breadcrumbsImg={bannerbg}
            /> */}
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style2 pt-110 md-pt-80 pb-110 md-pb-80">
                <div className="container">
                    < Editor />
                </div>
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default EditorMain;