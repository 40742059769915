import React from 'react';
import { Link } from 'react-router-dom';

import img from '../../assets/img/courses/main-home/1.jpg';

const CourseSingle = (props) => {
    return (
        <div className={props.itemClass}>
            <div className="courses-grid"
                style={{
                    height: '45vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div className="img-part">
                    <Link to={props.courseLink}>
                        <img
                            width="100%"
                            height="20vh"
                            src={props.image ? props.image : img}
                            alt={props.title}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '18vh'
                            }}
                        />
                    </Link>
                </div>
                <div className="content-part">
                    <div>
                        <h3 className="title">
                            <Link to={props.courseLink}>{props.title}</Link>
                        </h3>
                    </div>
                    {
                        props.blogDesc && (
                            <div className="blog-desc">
                                {
                                    props?.blogDesc &&
                                    <div dangerouslySetInnerHTML={{ __html: props?.blogDesc }}>
                                    </div>
                                }

                            </div>
                        )
                    }
                    <div style={{ justifySelf: 'baseline' }}>
                        <ul className="meta-part">
                            <li className="user">
                                <i className="fa fa-user"></i>
                                {props.studentQuantity} Upvotes
                            </li>
                            <li className="user">
                                <i className="fa fa-file"></i>
                                {props.lessonsQuantity}
                            </li>
                        </ul>
                    </div>
                    {/* <span className="course-qnty">{props.quantity}</span> */}
                </div>
            </div>
        </div>
    )
}

export default CourseSingle