import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import CourseSingle from '../../components/Courses/CourseSingle';


// Courses Image
import image1 from '../../assets/img/courses/main-home/1.jpg';
import image2 from '../../assets/img/courses/main-home/2.jpg';
import image3 from '../../assets/img/courses/home8/2.jpg';
import image4 from '../../assets/img/courses/home8/4.jpg';
import image5 from '../../assets/img/courses/home8/5.jpg';
import image6 from '../../assets/img/courses/home8/6.jpg';

import api from '../../api/api';
import apis from '../../api/apis';


const Courses = () => {

    const [blogs, setBlogs] = React.useState(null);

    React.useEffect(() => {
        api.get('/blog?page=1')
            .then(res => {
                setBlogs(res.data.slice(0, 6));
            })
            .catch(err => {
                console.log(err);
            });
    }, []);



    return (
        <div className="rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title3 text-center mb-44"
                    subtitleClass="sub-title"
                    subtitle="Select Blog"
                    titleClass="title black-color"
                    title="Explore Popular Blogs"
                />
                <div className="row">
                    {
                        blogs ? blogs.map((blog, i) => (
                            <div className="col-lg-4 col-md-6 mb-30" key={blog.id}>
                                <CourseSingle
                                    itemClass="courses-item"
                                    image={apis.cdn_base_url + '/file?filename=' + blog.cover}
                                    title={blog.title}
                                    pricing="Free"
                                    studentQuantity={blog.upvote}
                                    lessonsQuantity={blog?.categories[0]}
                                    courseLink={"/blog/single-post-full-width?id=" + blog.id}
                                    blogDesc={blog?.desc}
                                />
                            </div>
                        )) :
                            <div class="text-center">
                                <div class="spinner-border text-success" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Courses;