import React from 'react';
import Commenting from '../../components/Widget/Commenting';
import PostContent from '../../components/Widget/PostContent';
import PostNavigation from '../../components/Widget/PostNavigation';

const BlogDetails = (props) => {
    const { blog } = props;
    return (
        <>
            {
                blog ?
                    <PostContent blog={blog} /> :
                    <div class="text-center">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
            }
            {/* <PostNavigation /> */}
            <Commenting blog={blog} />
        </>
    );
}

export default BlogDetails;