import React from 'react';
import SinglePostSidebar from './SinglePostSidebar';
import SinglePostSix from '../../components/Blog/SinglePostSix';

import api from '../../api/api';
import apis from '../../api/apis';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const BlogMain = () => {

    const [blogs, setBlogs] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);

    React.useEffect(async () => {
        setBlogs([]);
        await api.get('/blog?page=' + page)
            .then(res => {
                if (res.data.length === 0) {
                    setPage(page - 1);
                    return;
                }
                setBlogs(res.data);
            })
            .catch(err => {
                console.log(err);
            });
        if (totalPages == 0) {
            await api.get('/stat/blog-count')
                .then(res => {
                    setTotalPages(Math.ceil(res.data.count));
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }, [page]);


    const changePage = (page) => {
        setPage(Math.max(1, Math.min(page, totalPages)));
    }

    return (
        <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 pr-50 md-pr-16">
                        {
                            blogs ?
                                <div className='row'>
                                    {/** Add pagination */}
                                    <div className="pagination-area orange-color text-center mb-30 md-mt-0">
                                        {/* <ul className="pagination-part">
                                        <li className="active" onClick={() => changePage(1)}><Link to="#">1</Link></li>
                                        <li onClick={() => changePage(2)}><Link to="#">2</Link></li>
                                        <li onClick={() => changePage(page + 1)}><Link to="#">Next <i className="fa fa-long-arrow-right"></i></Link></li>
                                    </ul> */}
                                        <ul className="pagination-part">
                                            <li onClick={() => changePage(page - 1)}><Link to="#">Previous <i className="fa fa-long-arrow-left"></i></Link></li>
                                            {
                                                Array.from({ length: totalPages }, (_, i) => (
                                                    <li key={i} className={page === i + 1 ? 'active' : ''} onClick={() => changePage(i + 1)}><Link to="#">{i + 1}</Link></li>
                                                ))
                                            }
                                            <li onClick={() => changePage(page + 1)}><Link to="#">Next <i className="fa fa-long-arrow-right"></i></Link></li>
                                        </ul>
                                    </div>
                                    {
                                        blogs && blogs.map((blog, i) => (
                                            <div className='col-lg-12 mb-70 md-mb-50' key={blog.id}>
                                                {
                                                    console.log('blog', apis.cdn_base_url + '/file?filename=' + blog.cover)
                                                }
                                                <SinglePostSix
                                                    blogImage={apis.cdn_base_url + '/file?filename=' + blog.cover}
                                                    blogAuthor={blog.creator_name}
                                                    blogCategory={blog?.categories?.length > 0 ? blog.categories[0].toUpperCase() : 'No Category'}
                                                    blogPublishedDate={new Date(blog.created_at).toDateString()}
                                                    blogTitle={blog.title}
                                                    blogDesc={blog.desc}
                                                    blogButtonClass='blog-button'
                                                    blogButtonText='Continue Reading'
                                                    id={blog.id}
                                                />
                                            </div>
                                        ))
                                    }
                                    {/** Add pagination */}
                                    <div className="pagination-area orange-color text-center mt-30 md-mt-0">
                                        {/* <ul className="pagination-part">
                                        <li className="active" onClick={() => changePage(1)}><Link to="#">1</Link></li>
                                        <li onClick={() => changePage(2)}><Link to="#">2</Link></li>
                                        <li onClick={() => changePage(page + 1)}><Link to="#">Next <i className="fa fa-long-arrow-right"></i></Link></li>
                                    </ul> */}
                                        <ul className="pagination-part">
                                            <li onClick={() => changePage(page - 1)}><Link to="#">Previous <i className="fa fa-long-arrow-left"></i></Link></li>
                                            {
                                                Array.from({ length: totalPages }, (_, i) => (
                                                    <li key={i} className={page === i + 1 ? 'active' : ''} onClick={() => changePage(i + 1)}><Link to="#">{i + 1}</Link></li>
                                                ))
                                            }
                                            <li onClick={() => changePage(page + 1)}><Link to="#">Next <i className="fa fa-long-arrow-right"></i></Link></li>
                                        </ul>
                                    </div>

                                </div>
                                :
                                <div class="text-center">
                                    <div class="spinner-border text-success" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SinglePostSidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogMain;