import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';

import ColasMain from './ColasMain';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import icon from '../../assets/img/logo/ai.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';
import logo from '../../assets/img/logo/csai.png';


import api from '../../api/api';


const Colas = () =>  {

    const [topics, setTopics] = React.useState([]);

    React.useEffect(() => {
        api.get('/tutorial/topic')
            .then((response) => {
                setTopics(response.data);
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);


    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={icon} />
            </Helmet>
            <OffWrap />
            <Header
                menu='solutions'
                parentMenu='solutions'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"                    
                emailAddress='contact@vasha.com.bd'
                Location='Science Complex Building, University of Dhaka, Dhaka 1000'
                topics={topics}
            />

            <ColasMain />

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            /> */}
            
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}

export default Colas;
