import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import logo from '../../assets/img/logo/csai.png';

const Workshop = () => {
    const bannerUrl = "https://cdn.vasha.com.bd/file?filename=banner_4_1727728380936.jpg";
    const qrUrl = "https://cdn.vasha.com.bd/file?filename=cail_workshop_1_4_1727704523213.png";
    const formUrl = "https://forms.gle/Sbc2zKsUwmSPCz9G8";
    const bKashUrl = "https://cdn.vasha.com.bd/file?filename=bkash_4_1727728102359.jpg"; // bKash QR Code URL
    const logo = "https://cdn.vasha.com.bd/file?filename=cail_logo_white_4_1727732846800.png";
    return (
        <React.Fragment>
            <OffWrap />
            <Header parentMenu='none' />

            {/* Banner Section */}
            {/* Include FontAwesome or Bootstrap Icons in your project */}
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" />

            {/* Banner Section */}
            <div className="container-fluid position-relative p-0 mb-5">
                <img
                    src={bannerUrl}
                    alt="Workshop Banner"
                    className="img-fluid w-100"
                    style={{ height: '100vh', objectFit: 'cover' }}
                />
                <div
                    className="position-absolute top-50 start-50 translate-middle text-center text-white bg-dark bg-opacity-50 p-4 rounded"
                    style={{ width: '85%', maxWidth: '1000px', padding: '40px' }} // Adjusted width and padding for better look
                >
                    {/* Workshop Title */}
                    <h1 className="display-4 fw-bold mb-4" style={{ color: 'white', lineHeight: '1.4', fontSize: '2.5rem' }}>
                        Hands-On Deep Learning & <br />
                        Open-source Large Language Model Workshop
                    </h1>

                    {/* Date and Location */}
                    <div className="text-center mb-5" style={{ fontSize: '1.2rem' }}>
                        <style>{`
        @media (max-width: 768px) {
            .responsive-container {
                flex-direction: column !important;
                text-align: center;
            }
            .responsive-container > div {
                max-width: 100% !important;
                margin-bottom: 20px; /* Add space between sections on small screens */
            }
            .responsive-container .align-items-end {
                align-items: center !important;
            }
        }
    `}</style>

                        <div className="responsive-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '85%', maxWidth: '1000px', margin: '0 auto' }}>
                            {/* Time and Location - Left Section */}
                            <div style={{ maxWidth: '50%' }}>
                                <div className="d-flex align-items-center mb-3">
                                    {/* Date Icon and Text */}
                                    <i className="fas fa-calendar-alt me-2" style={{ fontSize: '2.5rem', marginRight: '10px' }}></i>
                                    <span style={{ fontWeight: '500', fontSize: '1.1rem' }}>October 18-19, 2024</span>
                                </div>
                                <div className="d-flex align-items-start">
                                    {/* Location Icon and Text */}
                                    <i className="fas fa-map-marker-alt me-2" style={{ fontSize: '3rem', marginRight: '10px' }}></i>
                                    <div style={{ textAlign: 'left', fontSize: '1.1rem' }}>
                                        <span style={{ display: 'block' }}>Room: 704, 6th Floor</span>
                                        <span style={{ display: 'block' }}>Department of Computer Science and Engineering</span>
                                        <span style={{ display: 'block' }}>University of Dhaka</span>
                                    </div>
                                </div>
                            </div>

                            {/* Organized By and Logo - Right Section */}
                            <div className="d-flex flex-column align-items-end" style={{ maxWidth: '50%' }}>
                                <div className="d-flex flex-column align-items-center mb-3">
                                    <span className="mb-2" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Organized By</span>
                                    <figure className="d-flex flex-column align-items-center m-0">
                                        <img src={logo} alt="Organized by CAIL" style={{ height: '80px', width: 'auto' }} />
                                        <figcaption style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#fff', marginTop: '10px' }}>
                                            CAIL@CSEDU
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            {/* Workshop Details Section */}
            <div className="container my-5">
                <div className="row g-4">
                    {/* Workshop Information */}
                    <div className="col-lg-7">
                        <div className="card border-0 shadow-sm">
                            <div className="card-body p-4">
                                <h2 className="card-title mb-4">About the Workshop</h2>
                                <p
                                    className="mb-4"
                                    align='left'
                                    style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'justify'
                                    }}
                                >
                                    We are pleased to announce a two-day, hands-on workshop hosted by the Cognitive Agents and Interaction Lab, Department of Computer Science and Engineering, University of Dhaka. This workshop is designed for approximately 20 participants, providing practical, real-world experience with deep neural networks and large language models (LLMs). Each session will be led by one main instructor, supported by 3 to 4 co-instructors, ensuring personalized guidance throughout. Attendees will gain valuable insights into the theory, implementation, and deployment of state-of-the-art AI models, with 8 experts from both academia and industry contributing to the workshop.
                                </p>

                                <h3 className="mt-4">Objective</h3>
                                <p
                                    className="mb-4"
                                    align='left'
                                    style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'justify'
                                    }}
                                >
                                    The goal of this workshop is to equip participants with the knowledge and skills to implement deep neural networks and deploy large language models. Participants will learn how to utilize single or multiple GPUs during model pretraining, fine-tuning, and inference. Additionally, they will gain experience in deploying trained models so that they can be used by human users or integrated with web services. Each session is designed to provide hands-on experience, ensuring practical application of the concepts in real-world scenarios.
                                </p>

                                <h3 className="mt-4">Prerequisites</h3>
                                <p
                                    className="mb-4"
                                    align='left'
                                    style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'justify'
                                    }}
                                >
                                    Participants should have a basic level of expertise in Python.
                                </p>

                                <h3 className="mt-4">Sessions Overview</h3>
                                {/* Replace list-group with a standard <ul> */}
                                <ul className="mb-4" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                    <li className="mb-2">
                                        <strong>Session 1: Introducing Multi-Layer Perceptron (MLP) as a Deep Neural Network</strong>
                                        <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                                            <li>Overview of MLP as a foundational architecture in deep learning.</li>
                                            <li>Introduction to <b>PyTorch</b> for neural network implementation.</li>
                                            <li>Implementation of basic neural networks using <b>GPUs</b>.</li>
                                            <li>Discussion of common challenges faced by developers during network implementation.</li>
                                            <li><b>Hands-on</b> experience in designing and implementing simple neural networks using PyTorch.</li>
                                        </ul>
                                    </li>
                                    <li className="mb-2">
                                        <strong>Session 2: Understanding Transformers and Attention Mechanisms</strong>
                                        <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                                            <li>In-depth explanation of the <b>Transformer architecture</b> and its internal workings.</li>
                                            <li>The significance of the attention module and its impact on performance.</li>
                                            <li>Hands-on implementation of Transformers using PyTorch.</li>
                                            <li>Discussion on why Transformers are considered the foundation of <b>Large Language Models (LLMs)</b>.</li>
                                            <li>Hands-on experience in building and understanding transformer models.</li>
                                        </ul>
                                    </li>
                                    <li className="mb-2">
                                        <strong>Session 3: Utilizing Open-Source Large Language Models</strong>
                                        <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                                            <li>Overview of <b>LLAMA 3.2</b> and other open-source LLMs.</li>
                                            <li>Using large datasets for <b>pre-training</b> LLMs, with a discussion on how different types of data can be utilized effectively during the pre-training phase.</li>
                                            <li>Fine-tuning pre-trained models with a <b>custom-made dataset</b>, providing participants hands-on experience and exploring how fine-tuning impacts model deployment in real-world scenarios.</li>
                                            <li>Pros and cons of working with large language models.</li>
                                            <li>Hands-on experience in fine-tuning LLMs for specific tasks using the custom dataset.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Session 4: Deploying Trained Models for Applications</strong>
                                        <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                                            <li>Deploying trained models as services for human interaction or as APIs for integration with other applications, using a <b>public IP</b> to make the service accessible.</li>
                                            <li>Building a <b>GPU server</b> as the inference engine, with hands-on experience in deploying models and managing the server.</li>
                                            <li>Exploring challenges and solutions in real-world environments, including <b>public IP setup, multi-GPU deployment, and calculating VRAM requirements</b> for efficient model inference considering the number of users.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    {/* Payment Information and QR Code */}
                    <div className="col-lg-5">
                        <div className="card border-0 shadow-sm">
                            <div className="card-body p-4 text-center">
                                <h2 className="card-title mb-4">Workshop Information</h2>

                                {/**Suggestions */}
                                <div className="alert alert-warning text-start"
                                    role="alert"
                                    style={{
                                        padding: '10px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <strong>Recommendations:</strong><br />
                                    <ul className="mb-4" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                        <li>Unix based OS, specially <b>Ubuntu</b> is recommended for Session 4</li>
                                        <li>Familiarity with Google Colab and Kaggle would be helpful</li>
                                    </ul>
                                </div>

                                {/**Workshop Schedule*/}
                                <div
                                    className="alert alert-info text-start"
                                    role="alert"
                                    style={{
                                        padding: '10px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <li><strong>Venue:</strong> Science Complex Building(Room: 704, Lift: 6), Mokarrom Area, DU</li>
                                    <strong>Workshop Schedule:</strong><br />
                                    <ul className="mb-4" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                        <li><strong>Day 1:</strong> October 18, 2024(Friday)</li>
                                        <ul style={{ listStyleType: 'circle', paddingLeft: '40px' }}>
                                            <li><strong>Session 1 (9am - 12:15pm)</strong></li>
                                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                                                <li>Phase 1: 9am-10:30am</li>
                                                <li>Tea Break: 10:30am-10:45am</li>
                                                <li>Phase 2: 10:45am-12:15pm</li>
                                            </ul>
                                            <li><strong>Lunch & Prayer: 12:15am-2pm</strong></li>
                                            <li><strong>Session 2 (2pm - 5pm)</strong></li>
                                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                                                <li>Phase 1: 2pm-3:25pm</li>
                                                <li>Tea Break:  3:25pm-3:40pm</li>
                                                <li>Phase 2: 3:40pm-5pm</li>
                                            </ul>
                                        </ul>
                                        <li><strong>Day 2:</strong> October 19, 2024(Saturday)</li>
                                        <ul style={{ listStyleType: 'circle', paddingLeft: '40px' }}>
                                            <li><strong>Session 3 (9:30am - 12:45pm)</strong></li>
                                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                                                <li>Phase 1: 9:30am-11am</li>
                                                <li>Tea Break: 11am-11:15am</li>
                                                <li>Phase 2: 11:15am-12:45pm</li>
                                            </ul>
                                            <li><strong>Lunch & Prayer(12:45am-1:30pm)</strong></li>
                                            <li><strong>Session 4 (1:30pm - 4:45pm)</strong></li>
                                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                                                <li>Phase 1: 1:30pm-3pm</li>
                                                <li>Tea Break: 3pm-3:15pm</li>
                                                <li>Phase 2: 3:15pm-4:45pm</li>
                                            </ul>
                                            <li><strong>Ending Ceremony(5pm)</strong></li>
                                        </ul>
                                    </ul>
                                </div>





                                {/**Registration info */}
                                {/* <div
                                    className="alert alert-warning text-start"
                                    role="alert"
                                    style={{
                                        padding: '10px',
                                        borderRadius: '10px',
                                        visibility: 'hidden'
                                    }}
                                >
                                    <strong>Registration Deadline:</strong> October 14, 2024<br />
                                    <strong>Reg. Form: </strong>
                                    <a
                                        href="https://forms.gle/Sbc2zKsUwmSPCz9G8"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Register Here
                                    </a><br />
                                    <strong>Registration Fee:</strong> BDT 4000 <br />
                                    <strong>Payment Method (bKash SEND MONEY):</strong> 01720626449 <br />(reference: LLM_WORKSHOP)<br />
                                    <strong>EMAIL: </strong><a href='mailto:cail@cse.du.ac.bd'>cail@cse.du.ac.bd</a>

                                </div> */}

                                {/*Workshop info */}
                                {/* <div
                                    className="alert alert-info mt-3 text-start"
                                    role="alert"
                                    style={{
                                        visibility: 'hidden',
                                    }}
                                >
                                    <p
                                        className="mb-4"
                                        align='left'
                                        style={{
                                            whiteSpace: 'pre-line',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        <strong>NOTE</strong><br />
                                        <ul className="mb-4" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                            <li>
                                                Participants will receive a certificate upon successful completion of the workshop.
                                            </li>
                                            <li>
                                                Each day will consist of two sessions, each lasting 3 hours.
                                            </li>
                                            <li>
                                                Foods and refreshments will be provided.
                                            </li>
                                            <li>
                                                Approximately 25% of the participants will get scholarship as refund based on their performance and financial situation.
                                            </li>
                                        </ul>
                                    </p>
                                </div> */}

                                {/* QR Code Section */}
                                {/* <div className="d-flex justify-content-center my-4 flex-wrap"
                                    style={{
                                        visibility: 'hidden',
                                    }}
                                >
                                    <div className="bg-light p-3 rounded d-flex flex-column align-items-center me-3 mb-3">
                                        <h5>bKash QR Code</h5>
                                        <img src={bKashUrl} alt="bKash QR Code" className="img-fluid" style={{ width: '150px', height: '150px' }} />
                                    </div>
                                    <div className="bg-light p-3 rounded d-flex flex-column align-items-center mb-3">
                                        <h5>Reg. Form QR Code</h5>
                                        <img src={qrUrl} alt="Workshop QR Code" className="img-fluid" style={{ width: '150px', height: '150px' }} />
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer footerClass="rs-footer home9-style main-home" footerLogo={logo} />
            <SearchModal />
        </React.Fragment>
    );
}

export default Workshop;
