import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';

import CourseSidebar from './CourseSidebarSection';
import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';
import apis from '../../api/apis';

// Course courseImg
// import courseImg1 from '../../assets/img/courses/1.jpg';
// import courseImg2 from '../../assets/img/courses/2.jpg';
// import courseImg3 from '../../assets/img/courses/3.jpg';
// import courseImg4 from '../../assets/img/courses/4.jpg';
// import courseImg5 from '../../assets/img/courses/5.jpg';
// import courseImg6 from '../../assets/img/courses/6.jpg';
// import courseImg7 from '../../assets/img/courses/7.jpg';
// import courseImg8 from '../../assets/img/courses/8.jpg';

const CoursePart = (props) => {
    const [viewList, setViewList] = React.useState(false);
    if(document.getElementById("rs-popular-course") && !viewList)
        document.getElementById("rs-popular-course").classList.add('list-view');
    // window.location.reload();
    const listClassAdd = () => {
        setViewList(false);
        document.getElementById("rs-popular-course").classList.add('list-view');
    };

    const listClassRemove = () => {
        setViewList(true);
        document.getElementById("rs-popular-course").classList.remove('list-view');
    };
    const [topic, setTopic] = React.useState(null);

    useEffect(() => {
        setTopic(props?.topic);
    }, [props, viewList]);

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 pr-50 md-pr-14">
                        <div className="course-search-part">
                            <div className="course-view-part">
                                <div className="view-icons">
                                    <button onClick={listClassRemove} className="view-grid mr-10"><i className="fa fa-th-large"></i></button>
                                    <button onClick={listClassAdd} className="view-list"><i className="fa fa-list-ul"></i></button>
                                </div>
                                <div className="view-text">Showing {topic?.tutorials?.length} results</div>
                            </div>
                            {/* <div className="type-form">
                                <form method="post" action="#">
                                    <div className="form-group mb-0">
                                        <div className="custom-select-box">
                                            <select id="timing">
                                                <option>Default</option>
                                                <option>Newest</option>
                                                <option>Old</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>

                        {
                            topic && topic?.tutorials?.map((tutorial, index) => {
                                return (
                                    <CourseSingleTwo
                                        courseClass="courses-item m-1"
                                        courseImg={apis.cdn_base_url+'/file?filename='+tutorial.cover}
                                        courseTitle={tutorial.title}
                                        btnText="View "
                                        id={tutorial.id}
                                        upvote={tutorial.upvote}
                                        topicName={topic.name}
                                        date={tutorial.created_at}
                                        viewList={viewList}
                                        desc={tutorial?.desc}
                                        // coursePrice={topic.price}
                                    />
                                );
                            })
                        }

                        {/* <div className="pagination-area orange-color text-center mt-30 md-mt-0">
                            <ul className="pagination-part">
                                <li className="active"><Link to="#">1</Link></li>
                                <li><Link to="#">2</Link></li>
                                <li><Link to="#">Next <i className="fa fa-long-arrow-right"></i></Link></li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-lg-4 col-md-12 order-last">
                        <CourseSidebar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoursePart;