import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../../api/api';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu } = props;
    const location = useLocation();
    const [topics, setTopics] = useState([]);
    const [user, setUser] = useState(localStorage.getItem('user'));

    React.useEffect(() => {
        api.get('/tutorial/topic')
            .then((response) => {
                setTopics(response.data);
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            });

    }, []);

    return (
        <React.Fragment>

            {/** Home menu */}
            <li className={parentMenu === 'home' ? ' current-menu-item' : ''}>
                <Link to="/">Home</Link>
            </li>

            {/** Products menu */}
            <li className={parentMenu === 'solutions' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">solutions</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to={{ pathname: "https://ocr.vasha.com.bd/" }} target="_blank" className={secondParentMenu === 'team' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>OCR</Link>
                    </li>
                    <li>
                        <Link to="/colas" className={secondParentMenu === 'event' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>COLAS</Link>
                    </li>
                    <li>
                        <Link to="/summarizer" className={secondParentMenu === 'event' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Summarizer</Link>
                    </li>
                </ul>
            </li>

            {/** Tutorial menu */}
            <li className={parentMenu === 'tutorial' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/course-categories" as="#">Tutorials</Link>
                <ul className="sub-menu">
                    {topics.map((topic, index) => (
                        <li key={index}>
                            <Link to={`/tutorial?id=${topic.id}`} className={location.pathname === `/tutorial?id=${topic.id}` ? "active-menu" : ""}>{topic.name}</Link>
                        </li>
                    ))}
                </ul>
            </li>

            {/** Blog menu */}
            <li className={parentMenu === 'blog' ? 'current-menu-item' : ''}>
                <Link to="/blog">Blogs</Link>
                {/* <ul className="sub-menu">
                    <li>
                        <Link to="/blog" className={location.pathname === "/blog" ? "active-menu" : ""}>Blog</Link>
                    </li>
                    <li>
                        <Link to="#" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Blog Sidebar</Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/blog/blog-left-sidebar" className={location.pathname === "/blog/blog-left-sidebar" ? "active-menu" : ""}>Blog Left Sidebar</Link>
                            </li>
                            <li>
                                <Link to="/blog/blog-right-sidebar" className={location.pathname === "/blog/blog-right-sidebar" ? "active-menu" : ""}>Blog Right Sidebar</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="#" className={secondParentMenu === 'blogSingle' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Single Post</Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/blog/single-post-left-sidebar" className={location.pathname === "/blog/single-post-left-sidebar" ? "active-menu" : ""}>Post Left Sidebar</Link>
                            </li>
                            <li>
                                <Link to="/blog/single-post-right-sidebar" className={location.pathname === "/blog/single-post-right-sidebar" ? "active-menu" : ""}>Post Right Sidebar</Link>
                            </li>
                            <li>
                                <Link to="/blog/single-post-full-width" className={location.pathname === "/blog/single-post-full-width" ? "active-menu" : ""}>Full Width Post</Link>
                            </li>
                        </ul>
                    </li>
                </ul> */}
            </li>
            <li className={parentMenu === 'competition' ? 'current-menu-item' : ''}>
                <Link to="/competition">Compete</Link>
            </li>


            {/** About */}
            {/* <li className={parentMenu === 'about' ? ' current-menu-item' : ''}>
                <Link to="/about">About</Link>
            </li> */}

            {/** Contact */}
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact">
                    Contact
                </Link>
            </li>

            {/** Write */}
            <li className={parentMenu === 'write' ? 'current-menu-item' : ''}>
                <Link to={user ? "/write" : "/login"}>
                    Write
                    <i className="fa fa-pencil-square-o fa-3x fa-black" style={{ color: "gray" }}></i>
                </Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;