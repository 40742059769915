import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import RenderArticle from '../../components/Render/RenderArticle';
import TeamRegistrationForm from './TeamRegistrationForm';
import { Link } from 'react-router-dom';


const Contest = (props) => {
    const { contest, team, user } = props;
    const [key, setKey] = useState('overview');

    return (
        <Tabs
            id="competition-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            {/**Overview */}
            <Tab
                eventKey="overview"
                title={
                    <span style={{ color: key === 'overview' ? '#21a7d0' : 'black' }}>
                        Overview
                    </span>
                }
            >
                <div>
                    <div className="blog-desc">
                        {contest && <RenderArticle data={JSON.parse(contest?.description)} />}
                    </div>
                </div>
            </Tab>

            {/**Leaderboard */}
            <Tab
                eventKey="leaderboard"
                title={
                    <span style={{ color: key === 'leaderboard' ? '#21a7d0' : 'black' }}>
                        Leaderboard
                    </span>
                }
            >
                <div>
                    <h2>To be Announced Later</h2>
                </div>
            </Tab>

            {/**Team */}
            {
                user && team &&
                <Tab
                    eventKey="team"
                    title={
                        <span style={{ color: key === 'team' ? '#21a7d0' : 'black' }}>
                            Team
                        </span>
                    }
                >
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4 col-sm-6 mb-4">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <span
                                                style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                                            >
                                                {team.team_name}
                                            </span>
                                            <span 
                                                className="badge badge-primary ml-2"
                                                style={{ 
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    padding: '5px 10px',
                                                    borderRadius: '10px',
                                                    backgroundColor: team.approved ? '#28a745' : '#ffc107',
                                                    marginLeft: '10px'
                                                }}
                                            >
                                                {team.approved ? 'Approved' : 'Pending'}
                                            </span>
                                        </div>
                                        <ul>
                                            <li className="card-text"><strong>Email:</strong><a href={"mailto:"+team.email}> {team.email}</a></li>
                                            <li className="card-text"><strong>Phone:</strong> <a href={'tel:'+team.phone}>{team.phone}</a></li>
                                            <li className="card-text"><strong>Members:</strong> {team.member_count}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {team.members.map((member, index) => (
                                <div key={index} className="col-md-4 col-sm-6 mb-4">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h4 className="card-title">Member {index+1}</h4>
                                            <ul>
                                                <li className="card-text"><strong>Name:</strong> {member.name}</li>
                                                <li className="card-text"><strong>Email:</strong><a href={"mailto:"+member.email}> {member.email}</a></li>
                                                <li className="card-text"><strong>Institute:</strong> {member.institute}</li>
                                                <li className="card-text"><strong>Session:</strong> {member.session}</li>
                                                <li className="card-text"><strong>Student ID:</strong> {member.student_id}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Tab>
            }
            {
                user && (!team) && (new Date(contest?.reg_deadline) > new Date()) &&
                <Tab
                    eventKey="register"
                    title={
                        <span style={{ color: key === 'register' ? '#21a7d0' : 'black' }}>
                            Register
                        </span>
                    }
                >
                    {/**Team Registration Form */}
                    <TeamRegistrationForm contest={contest} user={user} />
                </Tab>
            }
            {
                (!user) &&
                <Tab
                    eventKey="register"
                    title={
                        <span style={{ color: key === 'register' ? '#21a7d0' : 'black' }}>
                            Register
                        </span>
                    }
                >
                    <div>
                        Please <Link to="/login">Login</Link> to register for the contest.
                    </div>
                </Tab>
            }
        </Tabs>
    );
}

export default Contest;
