import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingle from '../../components/Categories/CategoriesSingle';

// Categories Image
import image1 from '../../assets/img/categories/main-home/1.jpg';
import image2 from '../../assets/img/categories/main-home/2.jpg';
import image3 from '../../assets/img/categories/main-home/3.jpg';
import image4 from '../../assets/img/categories/main-home/4.jpg';
import image5 from '../../assets/img/categories/main-home/5.jpg';
import image6 from '../../assets/img/categories/main-home/6.jpg';

// Categories Icon
import icon1 from '../../assets/img/categories/main-home/icon/1.png';
import icon2 from '../../assets/img/categories/main-home/icon/2.png';
import icon3 from '../../assets/img/categories/main-home/icon/3.png';
import icon4 from '../../assets/img/categories/main-home/icon/4.png';
import icon5 from '../../assets/img/categories/main-home/icon/5.png';
import icon6 from '../../assets/img/categories/main-home/icon/6.png';

import api from '../../api/api';
import apis from '../../api/apis';


const Categories = (props) => {
    const [topics, setTopics] = React.useState(null);
    const icons = [icon1, icon2, icon3, icon4, icon5, icon6];

    React.useEffect(() => {
        api.get('/tutorial/topic')
            .then((response) => {
                setTopics(response.data.slice(0, 6));
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);


    return (
        <div className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-80" id="categories">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title3 text-center mb-44"
                    subtitleClass="sub-title"
                    subtitle="Top Categories"
                    titleClass="title black-color"
                    title="Top Tutorials"
                />
                <div className="row mb-34">

                    {
                        topics ?
                            topics.map((topic, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 mb-30" key={index}>
                                        <CategoriesSingle
                                            itemClass="categories-items"
                                            image={apis.cdn_base_url + '/file?filename=' + topic.cover}
                                            title={topic.name}
                                            quantity={topic?.tutorial_count + " Tutorials"}
                                            iconImg={icons[index]}
                                            catLink={`/tutorial?id=${topic.id}`}
                                        />
                                    </div>
                                )
                            }) :
                            <div class="text-center">
                                <div class="spinner-border text-success" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                    }
                </div>
                <div className="text-center">
                    <Link className="readon orange-btn main-home" to="/course-categories">View All categories </Link>
                </div>
            </div>
        </div>
    );
}

export default Categories;