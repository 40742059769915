import React from 'react';
import ComingSoon from '../../components/ComingSoon';
import ScrollToTop from '../../components/Common/ScrollTop';
import SubmitForm from './Form';

const ColasMain = () => {
	return (
		<React.Fragment>

			{/* Coming Soon Start */}
			{/* <ComingSoon /> */}
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column'
				}}
			>
				<SubmitForm />
			</div>
			{/* Coming Soon End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default ColasMain;