import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import CompetitionMain from './CompetitionMain';
import logo from '../../assets/img/logo/csai.png';

const Competition = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='competition'
            />
            <CompetitionMain />
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default Competition;
