import React, { Component } from 'react';
import SliderDefault from '../../components/Slider/SliderDefault';
import Categories from './CategoriesSection';
import Courses from './CoursesSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import { Link } from 'react-router-dom';

class HomeMain extends Component {
	state = {
		showModal: false, // Set to true to show the modal when the page loads
	};

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		return (
			<React.Fragment>
				<style>{`
					/* Modal Overlay for dark shade background */
					.modal-overlay {
						position: fixed;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.7);
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 1000;
					}

					/* Modal Container Styling */
					.modal-container {
						background: linear-gradient(135deg, #e0f7fa, #a7ffeb);
						border-radius: 15px;
						box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
						width: 90%;
						max-width: 600px; /* Increased max width */
						text-align: center;
						padding: 10px 30px; /* Reduced top and bottom padding, increased side padding */
						position: relative;
					}

					/* Modal Header with Close Button */
					.modal-header {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 5px; /* Reduced margin for height adjustment */
					}

					.modal-header h2 {
						color: #00695c;
						margin: 0;
						font-weight: bold;
						font-size: 1.2em;
					}

					.close-button {
						background: none;
						border: none;
						font-size: 20px;
						cursor: pointer;
						color: #757575;
					}

					/* Modal Body Styling */
					.modal-body {
						margin-bottom: 10px; /* Reduced margin for height adjustment */
					}

					.modal-body p {
						color: #004d40;
						font-size: 0.9em;
					}

					/* Modal Footer Buttons */
					.modal-footer {
						display: flex;
						justify-content: center;
						gap: 10px;
					}

					.btn {
						background-color: #004d40;
						color: #fff;
						padding: 8px 20px; /* Wider padding horizontally */
						border: none;
						border-radius: 20px;
						text-decoration: none;
						cursor: pointer;
						transition: background-color 0.3s;
					}

					.btn:hover {
						background-color: #00332d;
					}

					.btn-secondary {
						background-color: #757575;
						color: #fff;
						padding: 8px 20px; /* Wider padding horizontally */
						border: none;
						border-radius: 20px;
						cursor: pointer;
						transition: background-color 0.3s;
					}

					.btn-secondary:hover {
						background-color: #5c5c5c;
					}

					/* LLM Workshop Section */
					.llm-workshop-section {
						background: linear-gradient(135deg, #e0f7fa, #a7ffeb);
						padding: 30px;
						text-align: center;
						border-radius: 15px;
						box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
						max-width: 600px;
						margin: 40px auto;
					}

					.llm-workshop-section h3 {
						color: #00695c;
						font-weight: bold;
						margin-bottom: 15px;
					}

					.llm-workshop-section p {
						color: #004d40;
						margin-bottom: 20px;
					}
				`}</style>

				{/* Modal for LLM Workshop */}
				{/* {this.state.showModal && (
					<div className="modal-overlay">
						<div className="modal-container">
							<div className="modal-header">
								<h2>Deep Learning & LLM Workshop</h2>
								<button className="close-button" onClick={this.handleCloseModal}>
									&times;
								</button>
							</div>
							<div className="modal-body">
								<p>Join our hands-on deep learning & Open-source LLM workshop</p>
							</div>
							<div className="modal-footer">
								<Link to="/llm-workshop" className="btn" onClick={this.handleCloseModal}>
									Learn More & Register
								</Link>
								<button className="btn-secondary" onClick={this.handleCloseModal}>
									Close
								</button>
							</div>
						</div>
					</div>
				)} */}

				{/* SliderDefault-start */}
				<SliderDefault />
				{/* SliderDefault-end */}

				{/**Add the llm workshop page joining content below */}
				<div className="llm-workshop-section">
					<h3>Hands-On Deep Learning & Open-source Large Language Model Workshop</h3>
					{/* <p>Join our hands-on deep learning & Open-source LLM workshop</p> */}
					<Link to="/llm-workshop" className="btn">
						Learn More
					</Link>
				</div>
				{/**llm-workshop section end */}

				{/* Categories-area-start */}
				<Categories />
				{/* Categories-area-end */}

				{/* Courses-area-start */}
				<Courses />
				{/* Courses-area-end */}

				{/* scrolltop-start */}
				<ScrollToTop scrollClassName="scrollup orange-color" />
				{/* scrolltop-end */}
			</React.Fragment>
		);
	}
}

export default HomeMain;
