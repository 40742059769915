import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

export default axios.create({
    // baseURL: 'http://localhost:8001',
    // baseURL: 'http://192.168.0.106:8060',
    baseURL: 'https://cail-api.vasha.com.bd',
    // baseURL: 'http://103.157.135.249:8000'
});