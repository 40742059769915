import React, { Component } from 'react';
import SliderCompetition from '../../components/Slider/SliderCompetition';
import ScrollToTop from '../../components/Common/ScrollTop';
import Contests from './ContestSection';
// import ComingSoon from '../../components/ComingSoon';

class CompetitionMain extends Component {

    render() {
        return (
            <React.Fragment>
                {/* SliderDefault-start */}
                <SliderCompetition />
                {/* SliderDefault-start */}
                <div id="compete">
                    <Contests />
                    {/* <ComingSoon /> */}
                </div>

                {/* scrolltop-start */}
                <ScrollToTop
                    scrollClassName="scrollup orange-color"
                />
                {/* scrolltop-end */}

            </React.Fragment>
        );
    }
}

export default CompetitionMain;