import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
// import SectionTitle from '../../components/Common/SectionTitle';
// import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';

import banner from '../../assets/img/banner/cail.png';


const ContactMain = () => {

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Contact"
                pageName="Contact"
                breadcrumbsImg={banner}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-6">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address="Science Complex, University of Dhaka, Dhaka, Bangladesh"
                            />
                        </div>
                        <div className="col-md-6">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Email Address"
                                iconClass="flaticon-email"
                                email="contact@cognistorm.ai"
                            />
                        </div>
                        {/* <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                phone="(+880)1720626449"
                            />
                        </div> */}
                    </div>
                </div>

                <div className="container">
                    <div className="row y-middle">
                        {/* <div className="col-lg-6 md-mb-30"> */}
                        <div className="md-mb-30">
                            <div className="map-canvas">
                                <iframe
                                    title="map"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14610.090169461406!2d90.3990829!3d23.7287386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8ef3976bbbd%3A0x1b3140066a1d7bb8!2sDepartment%20of%20Computer%20Science%20and%20Engineering%2C%20University%20of%20Dhaka!5e0!3m2!1sen!2sbd!4v1723266428185!5m2!1sen!2sbd"
                                    width="600"
                                    height="450"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade">

                                </iframe>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-widget">
                                <SectionTitle
                                    sectionClass="sec-title3 mb-36"
                                    titleClass="title black-color mb-14"
                                    title="Get in Touch"
                                    descClass="new-desc"
                                    description="Have some suggestions or just want to say hi? Our  support team are ready to help you 24/7."
                                />
                                <ContactForm
                                    submitBtnClass="btn-send"
                                    btnText="Submit"
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;