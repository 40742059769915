import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../api/api';

const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: true,
        timeZone: 'UTC'
    };

    return date.toLocaleString('en-US', options);
};

const Comments = (props) => {
    const { tutorial, user } = props;
    const [comments, setComments] = React.useState(null);

    React.useEffect(() => {
        fetchComments();
    }, [props]);


    const fetchComments = () => {
        if (!tutorial || !tutorial.id) return;
        api.get('/tutorial/comment?tutorial_id=' + tutorial.id)
            .then(res => {
                setComments(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteComment = (id) => {
        api.delete('/tutorial/comment?id=' + id, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`,
            },
            withCredentials: true,
        })
            .then(res => {
                toast.success('Comment deleted successfully');
                fetchComments();
            })
            .catch(err => {
                console.log(err);
                toast.error('Failed to delete comment');
            })
    }

    return (
        <div className="comment-list">
            <h3 className="reply-title mb-4">Comments</h3>
            <ul className="list-unstyled">
                {
                    comments &&
                    comments.map((comment, index) => (
                        <li key={index} className="mb-4">
                            <div className="comment p-3 bg-light rounded shadow-sm">
                                <div className="comment-author vcard d-flex justify-content-between align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={comment.commentator_picture}
                                            className="avatar rounded-circle me-3"
                                            alt="image"
                                            style={{ width: '30px', height: '30px' }}
                                        />
                                        <div>
                                            <h5 className="mb-0 text-muted" style={{ fontSize: '14px' }}>{comment.commentator_name}</h5>
                                            <div className="comment-meta" style={{ fontSize: '12px' }}>
                                                <a href="#">{formatDate(comment.commented_at)}</a>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        user && user.email === comment.commentator_email &&
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger p-2 hover-me-sm"
                                            style={{ fontSize: '12px', color: 'white', backgroundColor: 'gray' }}
                                            onClick={() => handleDeleteComment(comment.id)}
                                        >
                                            Delete
                                        </button>
                                    }
                                </div>
                                <div className="comment-body">
                                    <p className="mb-0">{comment.comment}</p>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    )
}


const Commenting = (props) => {
    const { tutorial } = props;
    const user = JSON.parse(localStorage.getItem('user'));
    const [editComment, setEditComment] = React.useState('');
    const [reloadComment, setReloadComment] = React.useState(false);

    // Link to login page if user is not logged in
    if (!user) {
        return (
            <div className="comment-area">
                <div className="comment-full">
                    <h3 className="reply-title">Leave a Reply</h3>
                    <p>
                        <span>Please </span>
                        <a href="/login">Login</a>
                        <span> to post a comment</span>
                    </p>
                    <p>
                        {/* <span>Your email address will not be published.</span> */}
                    </p>
                </div>
                <Comments tutorial={tutorial} user={null} />
            </div>
        );
    }

    const handleCommentChange = (e) => {
        setEditComment(e.target.value);
    }

    const handlePostComment = () => {
        if (!editComment) {
            toast.error('Please enter your comment');
            return;
        }
        api.post('/tutorial/comment?tutorial_id=' + tutorial.id, {
            "comment": editComment
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`,
            },
            withCredentials: true,
        })
            .then(res => {
                toast.success('Comment posted successfully');
                setEditComment('');
                setReloadComment(!reloadComment);
            })
            .catch(err => {
                console.log(err);
                toast.error('Failed to post comment');
            })
    }

    return (
        <div className="comment-area">
            <div className="comment-full">
                <Toaster />
                <h3 className="reply-title">Leave a Reply</h3>
                <p>
                    {/* <span>Your email address will not be published.</span> */}
                </p>
                <form id="contact-form" method="post" action="mailer.php">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mb-35">
                            <div className="form-group">
                                {/* <label>Your comment here...</label> */}
                                {/* <textarea cols="40" rows="10" className="textarea form-control" required></textarea> */}
                                <textarea
                                    name="comment"
                                    id="comment"
                                    className="textarea form-control"
                                    placeholder="Write your comment here..."
                                    value={editComment}
                                    onChange={handleCommentChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="submit-btn">
                    <input name="submit" type="submit" id="submit" className="submit" value="Post Comment" onClick={handlePostComment} />
                </div>
            </div>
            <Comments tutorial={tutorial} user={user} reload={reloadComment} />
        </div>
    )
}

export default Commenting;