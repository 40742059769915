import React from 'react';
// import CourseSidebar from './CourseSidebar';
import CourseDetailsTab from './CourseDetailsTab';
// import PostNavigation from '../../../components/Widget/PostNavigation';
import { Link } from 'react-router-dom';
import Commenting from './TutorialCommenting';

const CourseDetailsPart = (props) => {
    const [nextTutorial, setNextTutorial] = React.useState(null);
    const [prevTutorial, setPrevTutorial] = React.useState(null);

    React.useEffect(() => {
        if (props.topic && props.tutorial) {
            let tutorials = props.topic[0].tutorials;
            let index = tutorials.findIndex(tutorial => tutorial.id === props.tutorial.id);
            if (index > 0) {
                setPrevTutorial(tutorials[index - 1]);
            } else {
                setPrevTutorial(null);
            }
            if (index < tutorials.length - 1) {
                setNextTutorial(tutorials[index + 1]);
            } else {
                setNextTutorial(null);
            }
        }
    }, [props]);

    return (
        <React.Fragment>
            <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
                <div className="container">
                    <div className="col clearfix" align="center">
                        <div className="col-lg-8 md-mb-50">
                            <CourseDetailsTab tutorial={props.tutorial} topic={props.topic}/>
                        </div>
                        {/* <div className="video-row row-lg-4">
                            <CourseSidebar />
                        </div> */}
                    </div>
                    <Commenting tutorial={props.tutorial} />
                    <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between' }} >
                        {
                            prevTutorial &&
                            <div className="ps-navigation">
                                <ul>
                                    <li><Link to={'/course/course-single?id=' + prevTutorial.id}><span className="next-link"><i class='fa fa-arrow-left'></i> Prev</span></Link></li>
                                    <li><Link to={'/course/course-single?id=' + prevTutorial.id}><span className="link-text">{prevTutorial?.title}</span></Link></li>
                                </ul>
                            </div>
                        }
                        {
                            nextTutorial && prevTutorial &&
                            <div className="ps-navigation" align='right'>
                                <ul>
                                    <li><Link to={'/course/course-single?id=' + nextTutorial.id}><span className="next-link">Next <i class='fa fa-arrow-right'></i></span></Link></li>
                                    <li><Link to={'/course/course-single?id=' + nextTutorial.id}><span className="link-text">{nextTutorial?.title}</span></Link></li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div align='right'>
                        {
                            nextTutorial && (!prevTutorial) &&
                            <div className="ps-navigation" align='right'>
                                <ul>
                                    <li><Link to={'/course/course-single?id=' + nextTutorial.id}><span className="next-link">Next <i class='fa fa-arrow-right'></i></span></Link></li>
                                    <li><Link to={'/course/course-single?id=' + nextTutorial.id}><span className="link-text">{nextTutorial?.title}</span></Link></li>
                                </ul>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default CourseDetailsPart;