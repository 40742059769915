import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import FeatureStyle1 from '../Feature/FeatureStyle1';

import Banner from '../../assets/img/banner/Banner.png';

const SliderDefault = () => {

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-slider main-home">
                <Slider {...sliderSettings}>
                    <div className="slider-content slide3 img-fluid">
                        <div className="container">
                            <div className="content-part">
                                <div className="sl-sub-title"></div>
                                <h1 className="sl-title"></h1>
                                <div className="sl-btn">
                                    <HashLink className="readon orange-btn main-home" to="#compete">Let's Compete!</HashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
                {/* <FeatureStyle1 /> */}
            </div>
        </React.Fragment>
    );
}

export default SliderDefault;