import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const RenderArticle = ({ data }) => {
    const renderBlock = (block) => {
        switch (block.type) {
            case "header":
                const HeadingTag = `h${block.data.level}`;
                return (
                    <HeadingTag key={block.id} className={`text-${block.data.level * 2}xl font-bold mb-4`} align='left'>
                        {block.data.text}
                    </HeadingTag>
                );
            case "paragraph":
                return (
                    <p key={block.id} className="mb-4" align='left'
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'justify'
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: block.data.text }} />
                    </p>
                );
            case "list":
            return (
                <div key={block.id} className="mb-4" align='left'>
                    {
                        block.data.style == "unordered" ?
                            <ul>
                                {block.data.items.map((item, index) => (
                                    <div>
                                        <span className="mr-2"><b>• </b></span>
                                        <span>{item}</span>
                                    </div>
                                    // <li key={index}>{item}</li>
                                ))}
                            </ul> :
                            <ol className="list-decimal list-inside">
                                {block.data.items.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ol>
                    }
                </div>
            );
            case "image":
                return (
                    <div key={block.id} className="mb-4 flex flex-col items-center justify-center" align='center'>
                        <img src={block.data.file.url} alt={block.data.caption || ''} className="max-w-48 h-auto" />
                        {block.data.caption && (
                            <p className="text-sm text-gray-600 text-center mt-2" align='center'>{block.data.caption}</p>
                        )}
                    </div>
                );
            case "checklist":
                return (
                    <div key={block.id} className="mb-4">
                        {block.data.items.map((item, index) => (
                            <div key={index} className="flex items-center mb-1">
                                <input
                                    type="checkbox"
                                    checked={item.checked}
                                    readOnly
                                    className="mr-2"
                                />
                                <span>{item.text}</span>
                            </div>
                        ))}
                    </div>
                );

            case "raw":
                return (
                    <div key={block.id} className="mb-4">
                        <div dangerouslySetInnerHTML={{ __html: block.data.html }} />
                    </div>
                );
            case "math":
            case "Math":
                return (
                    <div key={block.id} className="mb-4" align='left'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: katex.renderToString(block.data.math, {
                                    throwOnError: false,
                                }),
                            }}
                        />
                    </div>
                );

            case "linkTool":
                return (
                    <div key={block.id} className="mb-4">
                        <a
                            href={block.data.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                        >
                            {block.data.link}
                        </a>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="mx-20 px-10 ">
            {data.blocks.map((block) => renderBlock(block))}
        </div>
    );
};

export default RenderArticle;