import React from 'react';
import { Link } from 'react-router-dom';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RenderArticle from '../../../components/Render/RenderArticle';


// import FaqPart from './FaqPart';
// import ReviewPart from './ReviewPart';
// import OverviewPart from './OverviewPart';
// import InstructorPart from './InstructorPart';
// import CurriculumPart from './CurriculumPart';

// import cdn_api from '../../../api/cdn_api';
// import apis from '../../../api/apis';



const CourseDetailsTab = (props) => {

    // let tab1 = "Overview",
    //     tab2 = "Curriculum",
    //     tab3 = "Instructor",
    //     tab4 = "Faq",
    //     tab5 = "Reviews"
    // const tabStyle = 'intro-tabs tabs-box';
    const [tutorial, setTutorial] = React.useState({});
    const [topic, setTopic] = React.useState(null);
    React.useEffect(() => {
        setTopic(props.topic);
        setTutorial(props.tutorial);
    }, [props]);

    return (
        <div className="blog-deatails">
            {/* <div className="bs-img">
                <img
                    width="100%"
                    src={apis.cdn_base_url + '/file?filename=' + tutorial.cover}
                    alt="Post Image"
                />
            </div> */}
            <div className="blog-full">
                <h2 className="title">{tutorial.title}</h2>
                <ul className="single-post-meta d-flex flex-row">
                    <li className='p-1 orage-hover'>
                        <span className="p-date"> <i className="fa fa-calendar-check-o"></i> {new Date(tutorial.created_at).toDateString()} </span>
                    </li>
                    {/* <li className='p-1'>
                        <span className="p-date"> <i className="fa fa-user-o"></i> {tutorial.creator_name} </span>
                    </li> */}
                    <li className="Post-cate p-1">
                        <div className="tag-line">
                            <i className="fa fa-book"></i>
                            
                            {
                                topic && 
                                <Link to={'/tutorial?id=' + topic[0].id}> {
                                    topic[0].name
                                }</Link>
                            }
                        </div>
                    </li>
                    {/* <li className="post-comment"> <i className="fa fa-comments-o"></i> {tutorial?.comments?.length}</li> */}
                </ul>
                <div className="blog-desc mt-4">
                    {
                        tutorial?.content && tutorial.content!=="" && <RenderArticle data={JSON.parse(tutorial.content)} />
                    }
                </div>
                {/* <Tabs>
                <TabList className={tabStyle}>
                    <Tab>
                        <button>{tab1}</button>
                    </Tab>
                    <Tab>
                        <button>{tab2}</button>
                    </Tab>
                    <Tab>
                        <button>{tab3}</button>
                    </Tab>
                    <Tab>
                        <button>{tab4}</button>
                    </Tab>
                    <Tab>
                        <button>{tab5}</button>
                    </Tab>
                </TabList>

                <TabPanel>
                    <OverviewPart />
                </TabPanel>

                <TabPanel>
                    <CurriculumPart />
                </TabPanel>

                <TabPanel>
                    <InstructorPart />
                </TabPanel>

                <TabPanel>
                    <FaqPart />
                </TabPanel>

                <TabPanel>
                    <ReviewPart />
                </TabPanel>
            </Tabs> */}
                {/* <ul className="nav nav-tabs intro-tabs tabs-box" id="myTab" role="tablist">
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn active" id="prod-overview-tab" data-toggle="tab" href="#prod-overview" role="tab" aria-controls="prod-overview" aria-selected="true">Overview</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-curriculum-tab" data-toggle="tab" href="#prod-curriculum" role="tab" aria-controls="prod-curriculum" aria-selected="false">Curriculum</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-instructor-tab" data-toggle="tab" href="#prod-instructor" role="tab" aria-controls="prod-instructor" aria-selected="false">Instructor</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-faq-tab" data-toggle="tab" href="#prod-faq" role="tab" aria-controls="prod-faq" aria-selected="false">Faq</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-reviews-tab" data-toggle="tab" href="#prod-reviews" role="tab" aria-controls="prod-reviews" aria-selected="false">Reviews</a>
                </li>
            </ul>
            <div className="tab-content tabs-content" id="myTabContent">
                <div className="tab-pane tab fade active show" id="prod-overview" role="tabpanel" aria-labelledby="prod-overview-tab">
                    <OverviewPart />
                </div>
                <div className="tab-pane fade" id="prod-curriculum" role="tabpanel" aria-labelledby="prod-curriculum-tab">
                    <CurriculumPart />
                </div>
                <div className="tab-pane fade" id="prod-instructor" role="tabpanel" aria-labelledby="prod-instructor-tab">
                    <InstructorPart />
                </div>
                <div className="tab-pane fade" id="prod-faq" role="tabpanel" aria-labelledby="prod-faq-tab">
                    <FaqPart />
                </div>
                <div className="tab-pane fade" id="prod-reviews" role="tabpanel" aria-labelledby="prod-reviews-tab">
                    <ReviewPart />
                </div>
            </div> */}
            </div>
        </div>
    );
}

export default CourseDetailsTab;