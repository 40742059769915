import React from 'react';
import Competition from './CompetitionCard';
import ComingSoon from '../../components/ComingSoon';

import api from '../../api/api';
import apis from '../../api/apis';



const Contests = () => {
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
    const [contests, setContests] = React.useState(null);

    React.useEffect(() => {
        api.get('/contest')
            .then(res => {
                setContests(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    if ((!contests) || contests.length === 0) {
        return (
            <div>
                <ComingSoon />
            </div>
        );
    }


    return (
        <div className="rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">


                {/**Ongoing... */}
                <div className="row">
                    {
                        contests && contests?.running?.map((contest, i) => (
                            <div className="col-lg-12 col-md-6 mb-30" key={contest.id}>
                                <Competition
                                    itemClass="courses-item"
                                    contest={contest}
                                    contestLink={"/contest?id=" + contest.id}
                                    status="running"
                                />
                            </div>
                        ))
                    }
                </div>

                {/**Upcoming... */}
                <div className="row">
                    {
                        contests && contests?.upcoming?.map((contest, i) => (
                            <div className="col-lg-12 col-md-6 mb-30" key={contest.id}>
                                <Competition
                                    itemClass="courses-item"
                                    contest={contest}
                                    contestLink={"/contest?id=" + contest.id}
                                    status="upcoming"
                                />
                            </div>
                        ))
                    }
                </div>



                {/**Past... */}
                <div className="row">
                    {
                        contests && contests?.past?.map((contest, i) => (
                            <div className="col-lg-12 col-md-6 mb-30" key={contest.id}>
                                <Competition
                                    itemClass="courses-item"
                                    contest={contest}
                                    contestLink={"/contest?id=" + contest.id}
                                    status="finished"
                                />
                            </div>
                        ))
                    }
                </div>
                {
                    (!contests) &&
                    <div class="text-center">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Contests;