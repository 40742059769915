import React from 'react';
import SingleTeam from '../../components/Team/SingleTeam';
import SectionTitle from '../../components/Common/SectionTitle';

// Team Members
import teamimg1 from '../../assets/img/team/1.jpg';
import teamimg2 from '../../assets/img/team/2.jpg';
import teamimg3 from '../../assets/img/team/3.jpg';
import teamimg4 from '../../assets/img/team/4.jpg';
import teamimg5 from '../../assets/img/team/5.jpg';
import teamimg6 from '../../assets/img/team/6.jpg';

const Team = () => {

    return (
        <React.Fragment>
            <div id="rs-team" className="rs-team style1 inner-style orange-style pt-102 pb-110 md-pt-64 md-pb-70 gray-bg">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title mb-50 md-mb-30 text-center"
                        subtitleClass="sub-title orange"
                        subtitle="Team"
                        titleClass="title mb-0"
                        title="Researcher Behind The Scene"
                    />
                    <div className="justify-center" align="center">
                        <div className="col-lg-4 col-md-6 mb-30" >
                            <SingleTeam
                                itemClass="team-item"
                                Image="https://media.licdn.com/dms/image/v2/C5603AQGyqiwLIaGtGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1526899789033?e=1729123200&v=beta&t=l7vK-KyebdTRbyT6wc-nCEkt1ij0SxgA2vZVXrl0mwY"
                                Title="Dr. Md. Mosaddek Khan"
                                Designation="Director"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Team;