import React, { useState } from 'react';
import { Form, Button, Toast, ToastContainer, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import api_colas from '../../api/api_colas';
import duplicate from '../../assets/icons/duplicate.png';
import { QuestionCircle } from 'react-bootstrap-icons';

const SubmitForm = () => {
    const [email, setEmail] = useState('');
    const [alpha, setAlpha] = useState(0); // Default alpha value is 0
    const [matchingDepth, setMatchingDepth] = useState(0); // Default value is 0
    const [zipFile, setZipFile] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        //file name ends with .zip
        if (file && file.name.endsWith('.zip')) {
            setZipFile(file);
        } else {
            alert('Please upload a valid zip file.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform validation or handle form submission logic here
        console.log({
            email,
            alpha,
            matchingDepth,
            zipFile
        });

        //check if zip file is uploaded
        if (!zipFile) {
            alert('Please upload a zip file.');
            return;
        }

        //formData
        const formData = new FormData();
        formData.append('email', email);
        formData.append('alpha', alpha);
        formData.append('level', matchingDepth);
        formData.append('file', zipFile);

        // Send formData to the server
        api_colas.post('/colas', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                setShowToast(true);
                // after 5 second, reload the page
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            })
            .catch((error) => {
                console.error('Error submitting form: ', error);
                setShowError(true);
            });

    };

    return (
        <div className="container mt-5">
            {/* Header */}
            <div align='center'
                style={{
                    marginBottom: '20px',
                }}
            >
                <h1
                    style={{
                        fontSize: '3rem',
                    }}
                >
                    COLAS
                    <img
                        src={duplicate}
                        alt="duplicate"
                        style={{
                            width: '50px',
                            height: '50px',
                            marginLeft: '10px',
                            marginBottom: '10px',
                        }}
                    />
                </h1>
                <span
                    style={{
                        fontSize: '30px',
                    }}
                >Check Plagiarism Among Codes </span>
            </div>

            {/* Form */}
            <Card
                style={{
                    padding: '10px',
                    borderRadius: '10px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Card.Body
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}
                >
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>
                                Email Address
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-matching-factor">
                                        Enter your email address to get the result
                                    </Tooltip>}
                                >
                                    <QuestionCircle className="ml-2" style={{ cursor: 'pointer' }} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Form.Group className="mb-3" controlId="formAlpha">
                                <Form.Label>
                                    Matching Factor
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-matching-factor">
                                            Lower the value, higher the similarity
                                        </Tooltip>}
                                    >
                                        <QuestionCircle className="ml-2" style={{ cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={alpha}
                                    onChange={(e) => setAlpha(parseFloat(e.target.value))}
                                />
                                <div>Value: {alpha}</div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formMatchingDepth">
                                <Form.Label>
                                    Matching Depth
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-matching-factor">
                                            Higher the value, more detailed the matching
                                        </Tooltip>}
                                    >
                                        <QuestionCircle className="ml-2" style={{ cursor: 'pointer' }} />
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={matchingDepth}
                                    onChange={(e) => setMatchingDepth(e.target.value)}
                                    required
                                >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3" controlId="formZipFile">
                            <Form.Label>
                                Upload Zip File
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-matching-factor">
                                        A zipped folder containing source codes
                                    </Tooltip>}
                                >
                                    <QuestionCircle className="ml-2" style={{ cursor: 'pointer' }} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                accept=".zip"
                                required
                            />
                        </Form.Group>

                        <Button className='btn-shop orange-color' variant="primary" type="submit" align='center' style={{ height: '' }}>
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
                <Card.Footer align='right'>
                    <small className="text-muted">
                        Core Contributor: <a href="https://rizveeredwan.github.io/" target="_blank" rel="noopener noreferrer">Redwan Ahmed Rizvee</a>
                    </small>
                </Card.Footer>
            </Card>

            <ToastContainer className="p-3 orange-color" position='top-right'>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">File Uploaded</strong>
                        <small>Just now</small>
                    </Toast.Header>
                    <Toast.Body>You will be notified through email!</Toast.Body>
                </Toast>
                <Toast onClose={() => setShowError(false)} show={showError} delay={5000} autohide bg='danger'>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                        <small>Just now</small>
                    </Toast.Header>
                    <Toast.Body>There was an error uploading file!</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default SubmitForm;
