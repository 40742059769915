import { Link } from 'react-router-dom';
import apis from '../../api/apis';

const SinglePostSix = (props) => {
    const { blogClass, blogImage, blogTitle, blogAuthor, blogPublishedDate, blogCategory, blogDesc, blogButtonClass, blogButtonText, id } = props;
    return (
        <div className={blogClass ? blogClass : 'blog-item'}>
            <div className="blog-img">
                <Link to={`/blog/single-post-full-width?id=${id}`}>
                    <img
                        src={blogImage}
                        alt={blogTitle}
                        width="100%"
                    />
                </Link>
            </div>
            <div className="blog-content">
                <h3 className="blog-title">
                    <Link to={`/blog/single-post-full-width?id=${id}`}>
                        {blogTitle ? blogTitle : 'University while the lovely valley team work'}
                    </Link>
                </h3>
                <div className="blog-meta">
                    <ul className="btm-cate">
                        <li>
                            <div className="blog-date">
                                <i className="fa fa-calendar-check-o"></i> {blogPublishedDate ? blogPublishedDate : 'September 14, 2020'}
                            </div>
                        </li>
                        <li>
                            <div className="author">
                                <i className="fa fa-user-o"></i> {blogAuthor ? blogAuthor : 'Admin'}
                            </div>
                        </li>
                        <li>
                            <div className="tag-line">
                                <i className="fa fa-book"></i>
                                <Link to="/blog">{blogCategory ? blogCategory : 'University'}</Link>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="blog-desc">
                    {
                        blogDesc &&
                        <div dangerouslySetInnerHTML={{ __html: blogDesc }}></div>
                    }
                </div>

                <div className={blogButtonClass ? blogButtonClass : 'blog-button'}>
                    <Link to={`/blog/single-post-full-width?id=${id}`} className="blog-btn">
                        {blogButtonText ? blogButtonText : 'Continue Reading'}
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default SinglePostSix