import React from 'react';
import Slider from "react-slick";
// import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// import FeatureStyle1 from '../Feature/FeatureStyle1';

// import Banner from '../../assets/img/banner/Banner.png';

const SliderDefault = () => {

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-slider main-home">
                <Slider {...sliderSettings}>
                    <div className="slider-content slide1">
                        <div className="container">
                            <div className="content-part">
                            

                                <div className="sl-title">
                                    COGNISTORM AI<br/>
                                    <div className="sl-title" style={{fontSize: '2.5rem'}}>A Research and Development Initiative</div>
                                </div>
                                <h1 className="sl-sub-title">Learn. Compete. Innovate... Explore the Potentials of AI with Us</h1>
                                <div className="sl-btn">
                                    <HashLink className="readon orange-btn main-home" to="#categories">Get Started</HashLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="slider-content slide2">
                        <div className="container">
                            <div className="content-part">
                                <div className="sl-sub-title">Make Your Life Easy</div>
                                <h1 className="sl-title">With Our Best AI Tools</h1>
                                <div className="sl-btn">
                                    <HashLink className="readon orange-btn main-home" to="#categories">Get Started</HashLink>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
                {/* <FeatureStyle1 /> */}
            </div>
        </React.Fragment>
    );
}

export default SliderDefault;