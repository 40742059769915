import React, { useState, useEffect } from "react";
import { Form, Input, Tag } from 'antd';
import EditorJS from '@editorjs/editorjs';

import Header from '@editorjs/header';
import LinkTool from '@editorjs/link';
import RawTool from '@editorjs/raw';
import ImageTool from '@editorjs/image';
import Checklist from '@editorjs/checklist'
import List from '@editorjs/list';
import Embed from '@editorjs/embed';
import InlineCode from '@editorjs/inline-code';


import EJLaTeX from 'editorjs-latex';

import toast, { Toaster } from 'react-hot-toast';
import katex from 'katex';
import 'katex/dist/katex.min.css';

import api from "../../api/api";
// import cdn_api from "../../api/cdn_api";
import apis from "../../api/apis";

import FileUpload from './FileUpload';

// import blue_banner from '../../assets/img/banner/blue_banner.jpg';

const Editor = () => {
    // const [coverImage, setCoverImage] = useState('blue_banner_4_1724595485596.jpg');
    const [title, setTitle] = useState('Click to Edit Title');
    const [editor, setEditor] = useState(null);
    const [categories, setCategories] = useState([]);

    const initEditor = async () => {
        setEditor(new EditorJS({
            holder: 'editorjs',
            placeholder: 'Start writing here...',
            autofocus: true,
            tools: {
                header: Header,
                linkTool: LinkTool,
                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+M',
                },
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile: async (file) => {
                                const fileUrl = await FileUpload(file);
                                if (fileUrl) {
                                    return {
                                        success: 1,
                                        file: {
                                            url: apis.cdn_base_url + '/file?filename=' + fileUrl
                                        }
                                    };
                                } else {
                                    return {
                                        success: 0
                                    };
                                }
                            }
                        },
                    }
                },
                embed: Embed,
                checklist: Checklist,
                list: List,
                raw: RawTool,
                math: {
                    class: EJLaTeX,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+M',
                    config: {
                        css: '.math-input-wrapper { padding: 5px; width: 100%; }',
                        placeholder: 'Enter LaTeX code here',
                        render: (latexCode) => {
                            try {
                                return katex.renderToString(latexCode, {
                                    throwOnError: false
                                });
                            } catch (error) {
                                console.error('Failed to render LaTeX:', error);
                                return latexCode;
                            }
                        }
                    }
                },
            },
        }));
    }

    const validateData = () => {
        // if (!coverImage) {
        //     toast.error('Cover Image is required');
        //     return false;
        // }
        if (title === 'Click to Edit Title') {
            toast.error('Title is required');
            return false;
        }
        return true;
    }


    const saveData = async () => {
        if (!validateData()) {
            return;
        }
        if (!editor) {
            toast.error('Editor is not initialized');
            return;
        }
        const access_token = await JSON.parse(localStorage.getItem('user'))?.access_token;
        if (!access_token) {
            toast.error('User Not Logged In or Log out and Log in again');
            return;
        }

        const res = await api.post('/blog', {
            "title": title,
            "content": JSON.stringify(await editor.save()),
            "categories": categories.map(cat => {
                return {
                    "category": cat
                }
            }),
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${access_token}`,
            },
            withCredentials: true,
        });

        if (res?.status === 201) {
            toast.success('Blog created successfully. Please Wait for the Approval', {
                duration: 4000,
                position: 'top-right',
            });
            await new Promise(r => setTimeout(r, 4000));
            window.location.href = '/blog';
        } else {
            toast.error('Failed to create blog');
        }
    }

    useEffect(() => {
        initEditor();
    }, []);

    // const handleImageUpload = async (e) => {
    //     const file = e.target.files[0];
    //     const fileUrl = await FileUpload(file);
    //     console.log(fileUrl);
    //     if (fileUrl) {
    //         setCoverImage(fileUrl);
    //     }
    // };

    const handleTitleChange = (e) => {
        setTitle(e.target.textContent);
    };
    // const handleCategoryChange = (value) => {
    //     setCategories(value);
    // };
    const handleRemoveCategory = (removedCategory) => {
        const updatedCategories = categories.filter(
            (category) => category !== removedCategory
        );
        setCategories(updatedCategories);
    };

    return (
        <div className="col-lg">
            <Toaster />

            {/**Cover */}
            {/* <div className="rs-breadcrumbs sec-color position-relative" style={{ height: '360px', overflow: 'hidden' }}>
                <img
                    src={coverImage ? apis.cdn_base_url + '/file?filename=' + coverImage : blue_banner}
                    alt="Cover"
                    className="img-fluid w-100"
                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                />
                <input
                    type="file"
                    id="coverImageInput"
                    className="d-none"
                    accept="image/*"
                    onChange={handleImageUpload}
                />
                <button
                    className="btn btn-primary position-absolute"
                    style={{
                        top: '10px',
                        right: '10px',
                        opacity: '0.8',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        width: '130px',
                        height: '50px',
                        fontSize: '0.8rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => document.getElementById('coverImageInput').click()}
                >
                    Change Cover
                </button>
            </div> */}


            {/**Title */}
            <div
                contentEditable
                className="p-3 text-center"
                style={{ bottom: '0px', fontSize: '2rem', cursor: 'pointer', opacity: '0.8' }}
                onBlur={handleTitleChange}
                suppressContentEditableWarning={true}
            >
                {title}
            </div>

            {/**Categories*/}
            <Form.Item label="Categories" className="mb-6 mt-5">
                <Input
                    placeholder="Enter category"
                    onPressEnter={(e) => {
                        const value = e.target.value.trim();
                        if (value && !categories.includes(value)) {
                            setCategories([...categories, value]);
                            e.target.value = "";
                        }
                    }}
                    className="h-12 px-4 rounded-md border-green-300 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                />
                <div className="mt-2">
                    {categories.map((category) => (
                        <Tag
                            key={category}
                            closable
                            onClose={() => handleRemoveCategory(category)}
                            className="mb-2 mr-2"
                        >
                            {category}
                        </Tag>
                    ))}
                </div>
            </Form.Item>

            {/**External Link and open external tab */}
            <div className="mt-4" align='right'>
                <a href="https://katex.org/docs/supported" target="_blank" rel="noreferrer">* Support for LaTeX</a>
            </div>
            {/**Editor */}
            <div id="editorjs" className="border mt-4">
            </div>
            {/**Submit Button */}
            <div className='logout p-2' align="center">
                <button className="btn-shop orange-color" type="submit" onClick={saveData} >Submit</button>
            </div>
        </div>
    );
}

export default Editor;