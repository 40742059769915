import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import AccountMain from '../../components/Shop/AccountMain';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import icon from '../../assets/img/logo/ai.png';
import logo from '../../assets/img/logo/csai.png';

import banner from '../../assets/img/banner/cail.png';

const MyAccount = () => {
    React.useEffect(() => {
        if(!localStorage.getItem('user')) {
            window.location.href = "/login";
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={icon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='shop'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"                    
                emailAddress='support@website.com'
                Location='374 William S Canning Blvd, MA 2721, USA '
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="My Account"
                pageName="Account"
                breadcrumbsImg={banner}
            />
            {/* breadcrumb-area-End */}

            <AccountMain />

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}
            
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default MyAccount;